import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
export default function InputLoc({
  label,
  onChange,
  name,
  placeholder,
  value,
  readOnly,
  required,
  disabled,
  options,
}) {
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.label,
  });
  return (
    <div className="div-input">
      <div className="label-input">{label}</div>
      <Autocomplete
        size="small"
        readOnly={readOnly}
        disabled={disabled}
        fullWidth
        // disableClearable
        id="filter-demo"
        value={value}
        onChange={onChange}
        options={options}
        getOptionLabel={(option) => option.label}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            required={required}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
    </div>
  );
}
