// export const roles = [
//   { code: 1, label: "superadmin" },
//   { code: 2, label: "dpd" },
//   { code: 3, label: "dpc" },
//   { code: 5, label: "dpra" },
//   { code: 4, label: "suksesor" },
// ];

export const roles = [
  { code: 1, label: "superadmin" },
  { code: 2, label: "dpd" },
  { code: 3, label: "dpc" },
  { code: 5, label: "dpra" },
  { code: 4, label: "sukkota" },
  { code: 6, label: "sukkec" },
  { code: 7, label: "sukkel" },
  { code: 8, label: "suktps" },
  { code: 9, label: "sukrw" },
  { code: 10, label: "sukrt" },
];

export const statuss = [
  { code: 1, label: "Pemilih Pasti" },
  { code: 2, label: "Pemilih Potensial" },
  { code: 3, label: "Tidak Memilih" },
  { code: 4, label: "Belum Teridentifikasi" },
];

export const optVerif = [
  { code: 0, label: "Belum Diverifikasi" },
  { code: 1, label: "Approve" },
  { code: 2, label: "Reject" },
];

export const kecamatans = [
  { code: 1, label: "CIBEBER" },
  { code: 2, label: "CILEGON" },
  { code: 3, label: "PULOMERAK" },
  { code: 4, label: "CIWANDAN" },
  { code: 5, label: "JOMBANG" },
  { code: 6, label: "GEROGOL" },
  { code: 7, label: "PURWAKARTA" },
  { code: 8, label: "CITANGKIL" },
];

export const kelurahans = [
  { code: 11, kec: 1, label: "BULAKAN" },
  { code: 12, kec: 1, label: "CIBEBER" },
  { code: 13, kec: 1, label: "CIKERAI" },
  { code: 14, kec: 1, label: "KALITIMBANG" },
  { code: 15, kec: 1, label: "KARANG ASEM" },
  { code: 16, kec: 1, label: "KEDALEMAN" },
  { code: 21, kec: 2, label: "BAGENDUNG" },
  { code: 22, kec: 2, label: "BENDUNGAN" },
  { code: 23, kec: 2, label: "CIWADUK" },
  { code: 24, kec: 2, label: "CIWEDUS" },
  { code: 25, kec: 2, label: "KETILENG" },

  { code: 31, kec: 3, label: "LEBAKGEDE" },
  { code: 32, kec: 3, label: "MEKARSARI" },
  { code: 33, kec: 3, label: "SURALAYA" },
  { code: 34, kec: 3, label: "TAMANSARI" },

  { code: 41, kec: 4, label: "BANJAR NEGARA" },
  { code: 42, kec: 4, label: "GUNUNG SUGIH" },
  { code: 43, kec: 4, label: "KEPUH" },
  { code: 44, kec: 4, label: "KUBANGSARI" },
  { code: 45, kec: 4, label: "RANDAKARI" },
  { code: 46, kec: 4, label: "TEGAL RATU" },

  { code: 51, kec: 5, label: "GEDONG DALEM" },
  { code: 52, kec: 5, label: "JOMBANG WETAN" },
  { code: 53, kec: 5, label: "MASIGIT" },
  { code: 54, kec: 5, label: "PANGGUNG RAWI" },
  { code: 55, kec: 5, label: "SUKMAJAYA" },

  { code: 61, kec: 6, label: "GEREM" },
  { code: 62, kec: 6, label: "GROGOL" },
  { code: 63, kec: 6, label: "KOTASARI" },
  { code: 64, kec: 6, label: "RAWA ARUM" },

  { code: 71, kec: 7, label: "KEBON DALEM" },
  { code: 72, kec: 7, label: "KOTABUMI" },
  { code: 73, kec: 7, label: "PABEAN" },
  { code: 74, kec: 7, label: "PURWAKARTA" },
  { code: 75, kec: 7, label: "RAMANUJU" },
  { code: 76, kec: 7, label: "TEGAL BUNDER" },

  { code: 81, kec: 8, label: "CITANGKIL" },
  { code: 82, kec: 8, label: "DRINGO" },
  { code: 83, kec: 8, label: "KEBONSARI" },
  { code: 84, kec: 8, label: "LEBAK DENOK" },
  { code: 85, kec: 8, label: "SAMANGRAYA" },
  { code: 86, kec: 8, label: "TAMAN BARU" },
  { code: 87, kec: 8, label: "WARNASARI" },
];

export const tpss = () => {
  let res = [];
  for (let i = 1; i <= 99; i++) {
    res.push({ code: `${i}`, label: i < 10 ? `0${i}` : `${i}` });
  }
  return res;
};

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mei",
  "Jun",
  "Jul",
  "Agu",
  "Sep",
  "Okt",
  "Nov",
  "Des",
];
