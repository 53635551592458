import React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";

export default function TitlePage({ title, to }) {
  const navigate = useNavigate();
  return (
    <div style={{ padding: "20px", display: "flex", alignItems: "center" }}>
      <IconButton onClick={() => navigate(to)}>
        <ArrowBackIosNewIcon
          sx={{ color: "black", width: "14px", height: "16px" }}
        />
      </IconButton>
      <Typography fontSize={18} fontWeight={600}>
        {title}
      </Typography>
    </div>
  );
}
